<template>
  <div>
    <v-card-title>
      {{ $t('Statistics_section') }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-row>
      <v-col
        md="3"
        sm="6"
        cols="12"
      >
        <v-card
          outlined
        >
          <v-card-text class="position-relative">
            <v-avatar
              size="60"
              color="primary"
              style="z-index: 10;"
              class="avatar-center"
            >
              <v-img :src="user.image ? user.image.original_url : require('@/assets/images/avatars/3.png')"></v-img>
            </v-avatar>
            <div class="d-flex justify-space-between flex-wrap pt-2">
              <div class="me-2 mx-5">
                <v-card-title class="pt-0 px-0">
                  {{ user.name }}
                </v-card-title>
                <v-card-subtitle class="text-xs pa-0">
                  <v-chip
                    :color="user.role.color"
                    small
                  >
                    {{ user.role.description }}
                  </v-chip>
                </v-card-subtitle>
              </div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="error"
                    v-bind="attrs"
                    v-on="on"
                    @click="logout"
                  >
                    <v-icon>
                      mdi-exit-to-app
                    </v-icon>
                  </v-btn>
                </template>
                <span>اضغط لتسجيل الخروج</span>
              </v-tooltip>
            </div>
          </v-card-text>
        </v-card>
      </v-col>


    </v-row>
    <v-card-title>
      {{ $t('StatisticsDirectorates') }}
      <v-spacer></v-spacer>
    </v-card-title>




    <v-row>
            <statistics-cards
              v-for="(directorate, i) in directorates" :key="i"
              :cols="6"
              :title="directorate.name"
              :statistics="directorate.statistics"
              :refresh="fetch"
              :show-overlay.sync="loading"
            ></statistics-cards>
    </v-row>
  </div>
</template>
<script>

import {
  mdiPhoneLog,
  mdiTownHall,

} from '@mdi/js'
import axios from '@axios'
import useVuetify from '@core/utils/vuetify'
import auth from '@/store/auth';
import StatisticsCard from '@/components/cards/StatisticsCard.vue';

import StatisticsCards from '@/components/cards/StatisticsCards.vue'

export default {
  name: 'HomePage',
  components: {
    StatisticsCard,

    StatisticsCards,
  },
  setup() {
    const { rootThemeClasses } = useVuetify()

    return {
      rootThemeClasses,
    }
  },
  data: () => ({
    loading: false,
    statistics: {
      active_phones: {
        title: 'allActivePhones',
        statistics: 0,
        icon: mdiPhoneLog,
        color: 'success',
        loading: true,
      },
      sent_phones: {
        title: 'allInactivePhones',
        statistics: 0,
        icon: mdiPhoneLog,
        color: 'info',
        loading: true,
      },
      locked_phones: {
        title: 'allLockedPhones',
        statistics: 0,
        icon: mdiPhoneLog,
        color: 'error',
        loading: true,
      },

    },
    directorates: [],
  }),
  computed: {
    user() {
      return auth.state.user;
    },
  },
  async mounted() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      try {
        // this.loading = true;
        const response = await axios.get('/statistics')
        this.directorates = response.data.data.numbers.map(numbers => ({
          ...number,
          statistics: [
            {
              title: 'allNumbersActive',
              statistics: number.new,
              icon: mdiPhoneLog,
              color: 'success',
            },

            {
              title: 'allNumbersInactive',
              statistics: number.active,
              icon: mdiPhoneLog,
              color: 'error',
            },
            {
              title: 'allSchoolActive',
              statistics: number.sent,
              icon: mdiTownHall,
              color: 'info',
            },
            {
              title: 'allSchoolActive',
              statistics: number.locked,
              icon: mdiTownHall,
              color: 'info',
            },

          ],
        }))
        console.log(this.directorates)
        Object.keys(response.data.data)
          .forEach(key => {
            if (this.statistics[key]) {
              this.statistics[key].statistics = response.data.data[key];
              this.statistics[key].loading = false;
            }
          });
      } finally {
        // this.loading = false
      }
    },
    logout() {
      auth.dispatch('logout')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.avatar-center {
  top: 1rem;
  right: -2rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}
</style>
