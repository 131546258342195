<template>
  <v-col :cols="cols" :md="md" :sm="sm">
    <v-card>
      <v-card-title class="align-center">
        <span v-if="title" class="font-weight-semibold text-base">{{ title }}</span>

        <v-spacer></v-spacer>

        <v-btn
          v-if="refresh"
          icon
          small
          class="me-n3 mt-n2"
          @click="refresh"
        >
          <v-icon>
            {{ mdiRefresh }}
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            v-for="(statistics, i) of statisticsList"
            :key="i"
          >
            <div class="d-flex">
              <v-avatar
                size="44"
                :color="statistics.color"
                rounded
                class="elevation-1"
              >
                <v-icon
                  dark
                  color="white"
                  size="30"
                >
                  {{ statistics.icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">
                  {{ $t(statistics.title) }}
                </p>
                <span class="text--primary text-xl font-weight-bold">{{ statistics.statistics }}</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-overlay
        v-model="showOverlay"
        absolute
        color="white"
        opacity="0.75"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-col>
</template>

<script>
import { mdiRefresh } from '@mdi/js';

export default {
  name: 'StatisticsCards',
  props: {
    statistics: {
      type: Array,
      default: () => [],
    },
    title: { type: String, default: null },
    cols: { type: Number, default: null },
    md: { type: Number, default: null },
    sm: { type: Number, default: null },
    refresh: { type: Function, default: null },
    showOverlay: { type: Boolean, default: true },
  },
  computed: {
    statisticsList() {
      return this.statistics;
    },
  },
  setup() {
    return { mdiRefresh }
  },
}
</script>

<style scoped>

</style>
