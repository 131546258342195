<template>
  <v-card
    outlined
    class="statistics-horizontal"
  >
    <div class="d-flex align-center">
      <v-avatar
        rounded
        :color="color"
        size="44"
        class="elevation-3"
      >
        <v-icon
          size="30"
          :color="$vuetify.theme.dark ? '#312d4b': '#fff'"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>

      <div class="ms-3">
        <span class="text-xs">{{ $t(title) }}</span>
        <p class="text-no-wrap mb-0">
          <span
            class="text-xl font-weight-semibold text--primary"
          >{{ statistics }}
          </span>
        </p>
      </div>
    </div>
    <v-overlay
      v-model="showOverlay"
      absolute
      color="white"
      opacity="0.75"
    >
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
export default {
  name: 'StatisticsCard',
  props: {
    title: { type: String, default: '' },
    statistics: { type: Number, default: 0 },
    icon: { type: String, default: '' },
    color: { type: String, default: '' },
    showOverlay: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.statistics-horizontal {
  padding: 1.25rem 4.063rem 1.125rem 1.25rem;
}

.v-application {
  &.v-application--is-rtl {
    .statistics-horizontal {
      padding: 1.25rem 1.125rem 1.25rem 4.063rem;
    }
  }
}
</style>
